<!--
 * @Description: 
 * @Author: JunLiangWang
 * @Date: 2023-02-22 16:54:24
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-04-20 00:13:57
-->
<template>
  <div>
    aboutMe
  </div>
</template>

<script>
export default {
   name:"aboutMe"
}
</script>

<style>

</style>